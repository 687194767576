import store from "@/store";
import { updateNotice, update, updateThanksCount } from "@/components/firebaseOperations";
import { getTargetThanksIcon } from "@/components/thanksIcons";
import marked from "marked";
import constants from "@/components/constants";
import { copyText } from "vue3-clipboard";
import { openModal } from "@/components/modalFunc";
import {
  collection,
  query,
  where,
  limit,
  onSnapshot,
} from "firebase/firestore";
import hljs from "highlight.js"; // Import code highlight file


/// #が入ったタグを、-sharpに変換する(url内で、パラメータ文字列として#が使えないため(アンカーになってしまう))
/// @param {string} tag - タグ
/// @return {string} - 整形後タグ
export function cleansingTag(tag: string): string {
  let cleansingTag = tag.toLowerCase();
  cleansingTag = cleansingTag.replace(/[^0-9a-z_-]/g, "");
  cleansingTag = cleansingTag.replace(/-/g, "_");

  return cleansingTag;
}

/// 日付をYYYY-MM-DDの書式で返すメソッド
/// @param {string} number - 日付を秒で表した数値
/// @return {string} - 日付
export function getFormatDate(seconds: number): string {
  const dt = new Date(seconds * 1000);
  const y = dt.getFullYear();
  const m = ("00" + (dt.getMonth() + 1)).slice(-2);
  const d = ("00" + dt.getDate()).slice(-2);
  return y + "/" + m + "/" + d;
}

/// thanksカウントを、表示する形に整形
/// @param {number} counts - thanksカウント
/// @return {string} - 整形後のカウント
export function getCleansingThanksCounts(counts: number): string {
  let cleansingCounts = counts.toString();

  if (counts > 999) {
    // 1000以上の時(1000未満の時は整形不要)
    if (counts < 1000000) {
      cleansingCounts = (counts / 1000).toFixed(1) + "k";
    } else {
      cleansingCounts = (counts / 1000000).toFixed(1) + "m";
    }
  }

  return cleansingCounts;
}

/// ユーザのsnsに関するリストを返す
/// @param {boolean} includeEmpty - 空のsns情報をリストに含めるか
/// @param {Map} user - ユーザ情報
/// @return {Array} - snsのリスト
export function getSns(
  includeEmpty: boolean,
  user: { [key: string]: any }
): {
  [key: string]: string;
}[] {
  const sns: { [key: string]: string }[] = [];
  for (const snsName of ["twitter", "github", "qiita", "zenn"]) {
    const urlParam = snsName + "_url";

    if (includeEmpty || user[urlParam] != "") {
      sns.push({
        name: snsName,
        url: user[urlParam],
      });
    }
  }

  return sns;
}

/// thanksを更新する
/// @param {any} state - state
export async function updatedThanksCount(state: any): Promise<void> {
  if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
    // ログインユーザ情報があるかどうか(ログインしているかどうか)
    if (store.state.myUser.users_id != state.article.users_id) {
      // 自分の記事では無かった場合
      if (state.clickedThanksIcons) {
        state.article.thanks--;
        await updateNotice(
          "subtraction",
          state.article.users_id
        );
      } else {
        state.article.thanks++;
        await updateNotice("add", state.article.users_id);
      }
      state.displayCounts = getCleansingThanksCounts(state.article.thanks);
      state.clickedThanksIcons = !state.clickedThanksIcons;
      state.thanksIconPath = getTargetThanksIcon(
        state.article.thanks
      );

      state.thanks = await updateThanksCount(
        state.article.id,
        state.article.thanks,
        state.article.docId,
        state.article.users_id
      );

      store.state.thanks[state.article.id] = state.thanks;
    }
  } else {
    openModal(state, constants.MODAL.LOGIN);
  }
}

/// thanksに関するドキュメントをサブスクライブする
/// @param {any} state - state
/// @return {any} - サブスクライブオブジェクト
export function getThanksSubscribe(state: any) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
    where("id", "==", state.article.id),
    limit(1)
  );

  return onSnapshot(q, async (snapshot: any) => {
    let thanksDoc: { [key: string]: any } = {};
    snapshot.docChanges().forEach((change: any) => {
      thanksDoc = change.doc.data();
    });

    if (state.article.thanks != thanksDoc.thanks) {
      state.article.thanks = thanksDoc.thanks;
      state.displayCounts = getCleansingThanksCounts(state.article.thanks);
      state.thanksIconPath = getTargetThanksIcon(
        state.article.thanks
      );
    }
  });
}

/// 記事を削除する(ドキュメントを削除するのではなく、is_enableをfalseにし、ドキュメント自体は残す)
/// @param {string} docId - ドキュメントID
export async function deleteArticle(docId: string): Promise<void> {
  await update(
    constants.FIREBASE_COLLECTION.ARTICLES,
    docId,
    {
      is_enable: false,
      is_open: false,
    }
  );
}

/// 記事の公開状態を変更する
/// @param {string} docId - ドキュメントID
/// @param {boolean} openStatus - 公開状態
async function _changeOpenStatus(
  docId: string,
  openStatus: boolean
): Promise<void> {
  await update(
    constants.FIREBASE_COLLECTION.ARTICLES,
    docId,
    {
      is_open: openStatus,
    }
  );
}

/// 文字列にマークダウンを適用する
/// @param {string} text - マークダウンを適用させたい文字列
/// @return {string} - マークダウンを適用した文字列
export function compiledMarkdownText(text: string): string {
  if (text !== undefined) {
    const renderer = new marked.Renderer();
    renderer.link = function(href: any, title: any, text: any) {
      return (
        '<a target="_blank" href="' +
        href +
        '" title="' +
        title +
        '">' +
        text +
        "</a>"
      );
    };

    marked.setOptions({
      langPrefix: '',
      highlight: function(code: any, lang: any) {
          return hljs.highlightAuto(code, [lang]).value
      }
  });

  return marked(text, { renderer: renderer });
  } else {
    return "";
  }
}

/// 記事削除ボタンをクリック(記事削除のためのモーダルを表示)
/// @param {any} state - state
export function clickedDeleteIcon(state: any): void {
  openModal(state, constants.MODAL.DELETE);
}

/// 記事公開ボタンをクリックした時の実行
/// @param {any} state - state
export async function clickedOpenButton(state: any): Promise<void> {
  state.article.is_open = !state.article.is_open;
  await _changeOpenStatus(state.article.docId, state.article.is_open);
}

/// 記事編集ボタンをクリック(記事編集ページに遷移)
/// @param {any} router - ルータオブジェクト
/// @param {any} state - state
export function clickedEditIcon(router: any, state: any): void {
  router.push({
    path: `/edit-article/${state.article.path_param_id}`,
  });
}

/// main codeをコピー
/// @param {any} e - element
/// @param {any} state - state
export function doCopy(e: any, state: any): void {
  const cleansingCopyText = _cleansingCopyText(e, state.article.main_code);
  copyText(cleansingCopyText, undefined, (error: any) => {
    if (error) {
      alert("Can not copy");
      console.log(error);
    } else {
      const tooltipElement: any = e.target.parentElement;
      tooltipElement.style.visibility = "visible";
      window.setTimeout(() => {
        tooltipElement.style.visibility = "hidden";
      }, 1000);
    }
  });
}

/// コピーするテキストを整形する
/// @param {string} text - コピー対象元テキスト
export function _cleansingCopyText(e: any, text: string): string {
  const cleansingText = text.trim();
  if (
    cleansingText.match(/^```[\s\S]*```$/) ||
    cleansingText.match(/^`[\s\S]*`$/)
  ) {
    // 最初と最後が共に``` or `の場合
    const codes = e.target.parentElement.parentElement.getElementsByTagName(
      "code"
    ); // code部分を取得
    if (codes.length === 1) {
      // main code部分にcodeが1つだけ書かれている場合
      return codes[0].textContent;
    }
  }

  return text;
}

/// タグをクリックした時
/// @param {string} tag - クリックしたタグ
/// @param {any} router - ルータオブジェクト
export function clickedTag(tag: string, router: any): void {
  tag = cleansingTag(tag);
  router.push({
    path: `/tag-search/${tag}`,
  });
}

/// プロフィールをクリックした時
/// @param {any} router - ルータオブジェクト
/// @param {string} name - プロフィールアカウント名
export function clickedProfile(router: any, name: string): void {
  router.push({
    path: `/${name}`,
  });
}

<template>
  <router-view />
</template>

<style lang="scss">
@import "@/assets/styles/globals.scss";
@import "@/assets/styles/markdown.scss";

#app {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    "Meiryo", "游ゴシック", "Yu Gothic", "ＭＳ Ｐゴシック", "MS PGothic",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $BASE_FONT_COLOR;
}

#main {
  max-width: 63em;
  margin: 0 auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  padding-top: 3.5em;
  padding-bottom: 2em;

  @include mq-down() {
    padding-top: 3em;
    padding-bottom: 4em;
  }

  &-child {
    margin-left: 1em;
    margin-right: 1em;

    @include mq-up(lg) {
      margin-left: 5em;
      margin-right: 5em;
    }
  }
}

body {
  margin: 0 !important;
}

body.modal-open {
  overflow: hidden;
}
</style>

import { defineComponent } from "vue";
import Header from "@/components/parts/Header.vue";
import BottomNavigation from "@/components/parts/BottomNavigation.vue";
import HomePage from "@/components/pages/HomePage.vue";

export default defineComponent({
  components: {
    Header,
    BottomNavigation,
    HomePage,
  },
})

import store from "@/store";
import { openModal } from "@/components/modalFunc"
import constants from "@/components/constants";

  /// 検索ボタンをクリックした時
  export function search(router: any) {
    router.push({
      path: "/search",
    });
  }

  /// ホームに行く
  export function goHome(router: any) {
    router.push({
      path: "/",
    });
  }

  /// 追加ボタンをクリックした時
  export function add(router: any, state: any) {
    if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
      router.push({
        path: "/add-article",
      });
    } else {
      openModal(state, constants.MODAL.LOGIN)
    }
  }

  /// プロフィールボタンをクリックした時
  export function clickedProfile(router: any, state: any) {
    if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
        router.push({
            path: `/${store.state.myUser.name}`,
        });
        } else {
          openModal(state, constants.MODAL.LOGIN)
        }
  }

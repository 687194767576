
import ArticleSummary from "@/components/parts/ArticleSummary.vue";
import { defineComponent, reactive } from "vue";
import { getArticles } from "@/components/firebaseOperations";
import InfiniteScroll from "infinite-loading-vue3";
import constants from "@/components/constants";

export default defineComponent({
  components: {
    ArticleSummary,
    InfiniteScroll,
  },
  async setup() {
    const state = reactive({
      articles: {
        docs: new Map()
      } as { [key: string]: any },
      infiniteScrollNoResult: false,
      infiniteScrollMessage: "",
    });

    /// 最後の要素に到達した時に実行される関数
    const infiniteHandler = async () => {
      if (
        state.articles.docs.size >= constants.UPDATE_BASE_COUNT && !state.infiniteScrollNoResult
      ) { // 更新処理を実行する必要があった場合
        const articles = await getArticles(
          state.articles.lastDocSnapshot
        );

        if (articles.docs.size !== 0) {
          for (const article of articles.docs.values()) {
            state.articles.docs.set(article.id, article);
          }

          state.articles.lastDocSnapshot = articles.lastDocSnapshot;
        } else {
          state.infiniteScrollNoResult = true;
        }
      }
    }

    state.articles = await getArticles(null);

    return {
      state,
      infiniteHandler,
    };
  },
});

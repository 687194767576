import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c762e48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "login" }
const _hoisted_2 = { id: "login-buttons" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.loginButtons, (buttonName) => {
        return (_openBlock(), _createElementBlock("div", {
          key: buttonName,
          class: "login-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickLoginButton()))
        }, [
          _createElementVNode("img", {
            src: require(`@/assets/images/snsIcons/${buttonName}.png`),
            alt: ""
          }, null, 8, _hoisted_3)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.state.errorMessage), 1)
    ])
  ]))
}

import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { getUsersId, register } from "@/components/firebaseOperations";
import store from "@/store";
import {
  GithubAuthProvider,
  signInWithPopup,
} from "firebase/auth";

export default defineComponent({
  setup() {
    const router = useRouter();

    const state = reactive({
      errorMessage: "",
      loginButtons: [ "github"],
    });

    /// ログインのための選択肢として用意されているボタンをクリックした時
    const clickLoginButton = () => {
      let provider = new GithubAuthProvider();

      signInWithPopup(store.state.auth, provider).then(async (result) => {
        const uid = result.user?.uid;
        document.body.classList.remove("modal-open");
        if (
          (await getUsersId(uid as string)).usersId == -1
        ) {
          // 新規登録だった場合
          await register(uid as string);
          router.push({
            path: "/edit-profile",
          });
        } else {
          // ログイン
          router.push({
            // 現在ログイン処理をしているページに遷移
            path: `/${router.currentRoute.value.fullPath}`,
          });
          router.go(0); // 描画再読み込み
        }
      });
    };

    return {
      state,
      clickLoginButton,
    };
  },
});

import store from "@/store";

export default {
  icons: [
    // thanksIcons
    "rabbit1.png",
    "rabbit2.png",
    "bambi.png",
    "squid.png",
    "jitte.png",
    "stonelantern1.png",
    "stonelantern2.png",
    "hotSpring.png",
    "panda1.png",
    "wateringCan.png",
    "weathercock.png",
    "dice.png",
    "playingCards.png",
    "mountFuji1.png",
    "mountFuji2.png",
    "mountFuji3.png",
    "squirrel.png",
    "martian.png",
    "jellyfish.png",
    "monkey.png",
    "woodenHorse.png",
    "feather.png",
    "moai.png",
    "moyai.png",
    "pine.png",
    "mitsudomoe.png",
    "taikyokuzu.png",
    "Seahorse.png",
    "bamboo.png",
    "gecko.png",
    "Hippopotamus.png",
    "puma.png",
    "bullfighting1.png",
    "monster.png",
    "kamon1.png",
    "goldRush.png",
    "oval.png",
    "gavel.png",
    "alien1.png",
    "chidori.png",
    "dogu.png",
    "origami.png",
    "daruma1.png",
    "otahuku.png",
    "noumen1.png",
    "dragon.png",
  ],
};

/// thanksIconそれぞれに対応するポイントを紐付けたMapを作成する
/// @return {Map} - 各thanksIconとポイントを紐づけたMap
export function createCorrespondenceTable() {
  const cleansingIcons: { [key: string]: string } = {};
  const icons = [
    // thanksIcons
    "rabbit1.png",
    "rabbit2.png",
    "bambi.png",
    "squid.png",
    "jitte.png",
    "stonelantern1.png",
    "stonelantern2.png",
    "hotSpring.png",
    "panda1.png",
    "wateringCan.png",
    "weathercock.png",
    "dice.png",
    "playingCards.png",
    "mountFuji1.png",
    "mountFuji2.png",
    "mountFuji3.png",
    "squirrel.png",
    "martian.png",
    "jellyfish.png",
    "monkey.png",
    "woodenHorse.png",
    "feather.png",
    "moai.png",
    "moyai.png",
    "pine.png",
    "mitsudomoe.png",
    "taikyokuzu.png",
    "Seahorse.png",
    "bamboo.png",
    "gecko.png",
    "Hippopotamus.png",
    "puma.png",
    "bullfighting1.png",
    "monster.png",
    "kamon1.png",
    "goldRush.png",
    "oval.png",
    "gavel.png",
    "alien1.png",
    "chidori.png",
    "dogu.png",
    "origami.png",
    "daruma1.png",
    "otahuku.png",
    "noumen1.png",
    "dragon.png",
  ];

  let points = 0;
  for (const icon of icons) {
    cleansingIcons[points] = icon;
    if (points < 5) {
      points++;
    } else if (points < 25) {
      points += 2;
    } else if (points < 50) {
      points += 5;
    } else if (points < 150) {
      points += 10;
    } else if (points < 500) {
      points += 50;
    } else {
      points += 100;
    }
  }

  return cleansingIcons;
}

/// 対象のthanksIconの画像パスを取得する
/// @param {number} thanksCount - thanksCount
/// @return {string} - 対象のthanksIconの画像パス
export function getTargetThanksIcon(thanksCount: number) {
  let iconPath = "";
  if (thanksCount >= 1200) {
    iconPath = store.state.thanksIcons[1200];
  } else {
    iconPath = store.state.thanksIcons[thanksCount];
    if (iconPath == undefined) {
      if (thanksCount < 25) {
        iconPath = store.state.thanksIcons[thanksCount - 1];
      } else {
        let baseValue = 0;
        if (thanksCount < 50) {
          baseValue = 5;
        } else if (thanksCount < 150) {
          baseValue = 10;
        } else if (thanksCount < 500) {
          baseValue = 50;
        } else {
          baseValue = 100;
        }

        const remainderThanksCount = thanksCount % baseValue; // 対象のthanksIconの画像パスの、keyとなっている値を導き出すための、丸め込み数を代入
        iconPath = store.state.thanksIcons[thanksCount - remainderThanksCount]; // thanksCountを丸め込んで、対象のthanksIconの画像パスを代入
      }
    }
  }

  return iconPath;
}

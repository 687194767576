export default {
    FIREBASE_COLLECTION: { // FIREBASEで利用しているコレクション名
        ARTICLES: 'articles',
        NOTIFICATIONS: 'notifications',
        PROFILES: 'profiles',
        THANKS: 'thanks',
        USERS: 'users',
        OGPS: 'ogps'
    },
    UPDATE_BASE_COUNT: 25, // 無限スクロールを実現する時、最初にどれだけ表示し、トリガーが起動した後、どれぐらい追加するのかを示す基準値
    DEFAULT_TITLE: "dog-ears - IT技術保存・共有サービス", // プロジェクト名
    MODAL: {
        LOGIN: 'loginModal',
        DISCARD: 'discardModal',
        DELETE: 'deleteModal',
        NOTIFICATIONS: 'notificationsModal',
        IMAGE_CROPPING: 'imageCroppingModal',
        OGP: 'ogpModal'
    },
    THEME_COLOR: '#fd2470' // テーマカラー
}
<template>
  <div id="header">
    <div id="header-title">
      <img
        id="header-ukraine"
        src="@/assets/images/header/ukraine.png"
        alt=""
      />
      <img
        id="header-logo"
        class="pointer"
        src="@/assets/images/header/dog-ears-text.svg"
        alt=""
        @click="goHome(router)"
      />
    </div>
    <router-link to="/about" id="header-about-sp" @click="about">
      <img src="@/assets/images/info-icon-white.png" alt="" />
    </router-link>
    <div id="header-nav">
      <div>
        <router-link to="/about" id="header-about" class="header-icon">
          <img src="@/assets/images/info-icon.png" alt="" />
        </router-link>
        <div
          id="header-search"
          class="header-icon"
          @click="search(router)"
        >
          <img src="@/assets/images/search-icon.png" alt="" />
        </div>
        <div
          id="header-add"
          class="header-icon"
          @click="add(router, state)"
        >
          <img src="@/assets/images/add-icon.png" alt="" />
        </div>
        <div
          v-if="
            Object.getOwnPropertyNames(store.state.myUser).length === 0 ||
              store.state.myUser.image_url == ''
          "
          id="header-default-profile-icon"
          :class="{
            'header-icon': true,
            'have-notification': store.state.haveThanksNotification,
          }"
          @click="clickedProfile(router, state)"
        >
          <img src="@/assets/images/profile-icon.png" alt="" />
        </div>
        <div
          v-else
          id="header-original-profile-icon"
          :class="{
            'header-icon': true,
            'have-notification': store.state.haveThanksNotification,
          }"
          @click="clickedProfile(router, state)"
        >
          <img :src="store.state.myUser.image_url" alt="" />
        </div>
      </div>
    </div>

    <DialogBase
      @close="closeModal(state, constants.MODAL.LOGIN)"
      v-if="state.loginModal"
    >
      <!-- default スロットコンテンツ -->
      <div id="dialog-close-icon-button-parent">
        <i
          class="far fa-times-circle"
          id="dialog-close-icon-button"
          @click="closeModal(state, constants.MODAL.LOGIN)"
        ></i>
      </div>
      <div id="dialog-promotion-text">ログインする必要があります</div>
      <LoginButtons></LoginButtons>
      <!-- /default -->
    </DialogBase>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import DialogBase from "@/components/parts/DialogBase.vue";
import LoginButtons from "@/components/parts/LoginButtons.vue";
import { goHome, search, add, clickedProfile } from "@/components/menuBarFunc.ts";
import { closeModal } from "@/components/modalFunc";
import constants from "@/components/constants";

export default defineComponent({
  components: {
    DialogBase,
    LoginButtons,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      loginModal: false,
    });

    /// アバウトページをクリックした時
    const about = () => {
      window.scrollTo(0, 0);
    };

    return {
      constants,
      state,
      store,
      router,
      goHome,
      search,
      add,
      clickedProfile,
      closeModal,
      about,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/globals.scss";
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,500&display=swap");
@import "@/assets/styles/baseDialog.scss";

$ORIGINAL_PROFILE_IMG_SIZE: 2.4em;

#header {
  height: 3.5em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $THEME_COLOR;
  position: fixed;
  z-index: 1000;

  @include mq-down() {
    height: 3em;
  }

  &-title {
    display: flex;
  justify-content: center;
  align-items: center;
  }

  &-ukraine {
    width: 3.75em;
    margin-left: 0.25em;

    @include mq-down() {
      width: 3.5em;
    }
  }

  &-logo {
    margin-bottom: 0.25em;
    margin-left: 0.25em;
    width: 8em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;

    font-size: $BASE_FONT_SIZE * 1.25;
    color: white;

    @include mq-down() {
      width: 7em;
      margin-top: 0.1em;
    }
  }

  &-about-sp {
    @include mq-up() {
      display: none;
    }

    > img {
      width: auto;
      height: 1.75em;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      margin-right: 1em;
    }
  }

  &-nav {
    @include mq-down() {
      display: none;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-about {
    > img {
      margin-right: 0.12em;
    }
  }

  &-original-profile-icon {
    padding: 0em;
    width: $ORIGINAL_PROFILE_IMG_SIZE;
    height: $ORIGINAL_PROFILE_IMG_SIZE;
    background-color: transparent;

    > img {
      width: $ORIGINAL_PROFILE_IMG_SIZE;
      height: $ORIGINAL_PROFILE_IMG_SIZE;
      border-radius: 3em;
    }
  }
}

.header-icon {
  width: 2.2em;
  height: 2.2em;
  margin-right: 1em;
  padding: 0.1em;
  background-color: white;
  border-radius: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > img {
    width: auto;
    height: 1.3em;
  }
}

.have-notification {
  border: 0.25em solid $NOTIFICATION_COLOR;
  background-color: $NOTIFICATION_COLOR !important;
}
</style>

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store";
import {
  thanksIcons,
  userRelated,
  setThanksOfStore,
} from "@/components/initFunc";
import {
  getSingleArticle,
  getProfileByUsersId,
  getProfileByUserName,
} from "@/components/firebaseOperations";
import constants from "@/components/constants";
import { onAuthStateChanged } from "firebase/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*", // 設定していないページが指定された場合
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {},
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search.vue"),
    meta: { title: "検索" },
  },
  {
    path: "/tag-search/:tag",
    name: "TagSearch",
    component: () => import("@/views/TagSearch.vue"),
    meta: {},
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { title: "ログイン" },
  },
  {
    path: "/:userName",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: {},
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: () => import("@/views/EditProfile.vue"),
    meta: { requiresAuth: true, title: "プロフィール編集" },
  },
  {
    path: "/add-article",
    name: "AddArticle",
    component: () => import("@/views/AddOrEdit.vue"),
    meta: { requiresAuth: true, title: "追加" },
  },
  {
    path: "/edit-article/:pathParamId",
    name: "EditArticle",
    component: () => import("@/views/AddOrEdit.vue"),
    meta: { requiresAuth: true, title: "記事編集" },
  },
  {
    path: "/article/:pathParamId",
    name: "Article",
    component: () => import("@/views/Article.vue"),
    meta: {},
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: { title: "見つかりませんでした" },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
    meta: { title: "dog-earsについて" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
    meta: { title: "プライバシーポリシー" },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: () => import("@/views/TermsOfUse.vue"),
    meta: { title: "利用規約" },
  },
  {
    path: "/credit",
    name: "Credit",
    component: () => import("@/views/Credit.vue"),
    meta: { title: "クレジット" },
  },
  {
    path: "/setting",
    name: "Setting",
    component: () => import("@/views/Setting.vue"),
    meta: { requiresAuth: true, title: "Setting" },
  },
];

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      store.state.auth,
      (user: any) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (process.env.NODE_ENV !== "production") {
    if (localStorage.getItem("authorized") == null) {
      const pass = prompt("パスワードは？");
      if (pass == "adminadmin") {
        localStorage.setItem("authorized", "YES");
      } else {
        next("/");
      }
    }
  }

  const title = to.meta.title;
  document.title = title
    ? title + " | " + constants.DEFAULT_TITLE
    : constants.DEFAULT_TITLE;

  try {
    const user = await getCurrentUser();

    // ログイン状況を渡して実行する関数
    thanksIcons(); // thanksIconに関する初期化

    if (user) {
      // ログインしていた場合
      await userRelated(user); // ログインユーザに関する初期化
      await setThanksOfStore();

      if (to.name == "Login") {
        // ログインしている状態でLoginページにアクセスした場合
        document.title = constants.DEFAULT_TITLE;
        next("/");
      }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // このルートはログインされているかどうか認証が必要です。
      // もしされていないならば、ログインページにリダイレクトします。
      if (user) {
        if (
          Object.getOwnPropertyNames(store.state.myUser).length != 0 &&
          store.state.myUser.first_profile_editing &&
          to.name != "EditProfile"
        ) {
          // 新規登録後、プロフィール編集が完了していなかった場合
          document.title = "プロフィール編集 | " + constants.DEFAULT_TITLE;
          next({ path: "/edit-profile" });
        } else if (to.name == "EditArticle") {
          const article = await getSingleArticle(
            to.params.pathParamId as string
          );
          if (Object.getOwnPropertyNames(article).length != 0) {
            store.commit("setArticleToEdit", article);
            next();
          } else {
            next("/");
          }
        } else {
          next();
        }
      } else {
        next("/");
      }
    } else {
      if (to.name == "Article") {
        const article = await getSingleArticle(to.params.pathParamId as string);
        const user = await getProfileByUsersId(article.users_id, false);
        if (
          Object.getOwnPropertyNames(article).length == 0 ||
          Object.getOwnPropertyNames(user).length == 0
        ) {
          router.push("/");
        } else {
          store.commit("setArticle", article);
          store.commit("setProfileUser", user);
          document.title = article.title + " | " + constants.DEFAULT_TITLE;
          next();
        }
      } else if (to.name == "Profile") {
        const user = await getProfileByUserName(to.params.userName as string);

        if (Object.getOwnPropertyNames(user).length == 0) {
          router.push("/");
        } else {
          store.commit("setProfileUser", user);
          document.title = user.name + " | " + constants.DEFAULT_TITLE;
          next();
        }
      } else {
        next();
      }
    }
  } catch (error) {
    next({ path: "/" });
  }
});

export default router;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f08d34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "main" }
const _hoisted_2 = { id: "main-child" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleSummary = _resolveComponent("ArticleSummary")!
  const _component_masonry = _resolveComponent("masonry")!
  const _component_infinite_scroll = _resolveComponent("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_infinite_scroll, {
        onInfiniteScroll: _ctx.infiniteHandler,
        message: _ctx.state.infiniteScrollMessage,
        noResult: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_masonry, {
            cols: { default: 4, 2200: 3, 1650: 2, 1100: 1 },
            gutter: 20
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.articles.docs.values(), (article) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: article.id,
                  class: "article"
                }, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ArticleSummary, { article: article }, null, 8, ["article"])
                    ]),
                    fallback: _withCtx(() => []),
                    _: 2
                  }, 1024))
                ]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onInfiniteScroll", "message"])
    ])
  ]))
}
import store from "@/store";
import { getUsersId, getProfileByUsersId, getMyThanksStatus } from "@/components/firebaseOperations";
import { createCorrespondenceTable } from "@/components/thanksIcons";

/// ログインユーザデータをstoreに保存し、thanksに関する通知サブスクライブを起動する
/// @param {any} user - ログインユーザ
export async function userRelated(user: any) {
  if (Object.getOwnPropertyNames(store.state.myUser).length == 0) {
    const usersId = (await getUsersId(user.uid)).usersId;
    if (usersId !== -1) {
      await getProfileByUsersId(usersId, true);
      store.dispatch("startNotificationSubscribe");
    }
  }
}

/// thanksIconをstoreに保存する
export function thanksIcons() {
  if (Object.getOwnPropertyNames(store.state.thanksIcons).length === 0) {
    store.commit(
      "setThanksIcons",
      createCorrespondenceTable() // storeに保存するための形式に変換する
    );
  }
}

/// ユーザの各記事に対するthanks状況をstoreに保存する
export async function setThanksOfStore() {
  if (
    Object.getOwnPropertyNames(store.state.myUser).length != 0 &&
    Object.getOwnPropertyNames(store.state.thanks).length === 0
  ) {
    const thanks = await getMyThanksStatus(
      store.state.myUser.users_id
    );

    store.commit("setThanks", thanks);
  }
}

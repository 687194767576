import { createStore } from "vuex";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import constants from "@/components/constants";

export default createStore({
  state: {
    myUser: {} as { [key: string]: any }, // ログインユーザ
    haveThanksNotification: false, // 確認できていない通知があるかどうか
    notificationSubscribe: undefined as any, // 通知サブスクライブオブジェクト
    thanks: {} as { [key: string]: any }, // 自分の各記事のthanks状況
    thanksIcons: {} as { [key: string]: string }, // ポイントと紐づけられたthanksIcons
    articleToEdit: {} as { [key: string]: any }, // 編集対象の記事
    article: {} as { [key: string]: any },
    profileUser: {} as { [key: string]: any },
    db: null as any,
    storage: null as any,
    auth: null as any,
  },
  mutations: {
    setMyUser(state, myUser) {
      state.myUser = myUser;
    },
    setHaveThanksNotification(state, haveThanksNotification) {
      state.haveThanksNotification = haveThanksNotification;
    },
    setThanksIcons(state, thanksIcons) {
      state.thanksIcons = thanksIcons;
    },
    setThanks(state, thanks) {
      state.thanks = thanks;
    },
    setArticleToEdit(state, articleToEdit) {
      state.articleToEdit = articleToEdit;
    },
    setArticle(state, article) {
      state.article = article;
    },
    setProfileUser(state, profileUser) {
      state.profileUser = profileUser;
    },
    setDb(state, db) {
      state.db = db;
    },
    setStorage(state, storage) {
      state.storage = storage;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
  },
  actions: {
    /// 通知に関するサブスクライブを設定
    startNotificationSubscribe() {
      const q = query(
        collection(this.state.db, constants.FIREBASE_COLLECTION.NOTIFICATIONS),
        where("users_id", "==", this.state.myUser.users_id)
      );

      this.state.notificationSubscribe = onSnapshot(q, (snapshot: any) => {
        // 指定したドキュメントに変更があった場合に発火
        snapshot.docChanges().forEach((change: any) => {
          const docData = change.doc.data();
          if (docData.thanks_notification_counts > 0) {
            // 確認していない通知があった場合(カウントが0より大きかった場合)
            this.state.haveThanksNotification = true;
          } else {
            this.state.haveThanksNotification = false;
          }
        });
      });
    },

    /// 通知に関するサブスクライブを停止
    stopNotificationSubscribe() {
      if (this.state.notificationSubscribe != undefined) {
        this.state.notificationSubscribe();
      }
    },
  },
  modules: {},
});

import { ref, getDownloadURL, uploadString } from "firebase/storage";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  doc,
  updateDoc,
  startAfter,
  setDoc,
} from "firebase/firestore";
import store from "@/store";
import constants from "@/components/constants";

/// 各ドキュメントと最後のスナップショットを取得
/// @param {any} snapshot - スナップショット
/// @return {any} - docsと抽出したドキュメントに関する最後のスナップショット
function _getDocsAndLastSnapshot(snapshot: any) {
  const docs = new Map();
  let lastDocSnapshot;
  snapshot.forEach((doc: any) => {
    const docData = doc.data();
    const id = docData["id"];
    docData["docId"] = doc.id;
    docs.set(id, docData);

    lastDocSnapshot = doc;
  });

  return {
    docs: docs,
    lastDocSnapshot: lastDocSnapshot,
  };
}

/// ユーザ名から、Profilesコレクションのスナップショットを取得
/// @param {string} userName - ユーザ名
/// @return {any} - Profilesコレクションのスナップショット
async function _getProfilesSnapshotByUserName(userName: string) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.PROFILES),
    where("is_enable", "==", true),
    where("name", "==", userName),
    limit(1)
  );

  return await getDocs(q);
}

async function _uploadTaskPromise(
  image: string,
  directory: string,
  path: string
): Promise<string> {
  const storageRef = ref(store.state.storage, `${directory}/${path}`);
  const uploadSnapshot = await uploadString(storageRef, image, "data_url");
  return await getDownloadURL(uploadSnapshot.ref);
}

/// Usersコレクションのidを取得
/// @param {string} uid - ユーザのuid
/// @return {int} - Usersコレクションのid
export async function getUsersId(uid: string) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.USERS),
    where("firebase_auth_uuid", "==", uid),
    limit(1)
  );
  const snapshot = await getDocs(q);
  let user: { [key: string]: any } = {};
  let usersId = -1;
  let docId = "";
  snapshot.forEach((doc: any) => {
    user = doc.data();
    usersId = user.id;
    docId = doc.id;
  });

  return {
    usersId: usersId,
    docId: docId,
  };
}

/// 引数で与えられたユーザが存在しているかどうか
/// @param {string} userName - ユーザ名
/// @return {boolean} - ユーザが存在するかどうか
export async function existUser(userName: string) {
  const snapshot = await _getProfilesSnapshotByUserName(userName);
  return snapshot.empty ? false : true;
}

/// ユーザ名を用いてプロフィールを取得
/// @param {string} userName - ユーザ名
/// @return {any} - プロフィール
export async function getProfileByUserName(userName: string) {
  const snapshot = await _getProfilesSnapshotByUserName(userName);
  let user: { [key: string]: any } = {};
  snapshot.forEach((doc: any) => {
    user = doc.data();
  });

  return user;
}

/// usersIdからプロフィールを取得
/// @param {number} usersId - usersId
/// @param {boolean} isSetMyUser - storeにuserを保存するかどうかを決めるフラグ
/// @return {any} - プロフィール
export async function getProfileByUsersId(
  usersId: number,
  isSetMyUser: boolean
) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.PROFILES),
    where("is_enable", "==", true),
    where("users_id", "==", usersId),
    limit(1)
  );
  const snapshot = await getDocs(q);
  let user: { [key: string]: any } = {};
  snapshot.forEach((doc: any) => {
    user = doc.data();
    user["docId"] = doc.id;

    if (isSetMyUser) {
      store.commit("setMyUser", user);
    }
  });

  return user;
}

/// firestoreの更新
/// @param {string} collectionName - コレクション名
/// @param {string} docId - ドキュメントID
/// @param {any} updateDoc - 更新対象ドキュメント
export async function update(
  collectionName: string,
  docId: string,
  document: any
) {
  document["updated_at"] = new Date();
  const ref = doc(store.state.db, collectionName, docId);
  await updateDoc(ref, document);
}

/// OGP画像を追加する
/// @param {string} url - OGP画像のURL
/// @param {string} pathParamId - 記事ID
export async function addOgp(url: string, title: string, pathParamId: string) {
  const now = new Date();
  await setDoc(
    doc(store.state.db, constants.FIREBASE_COLLECTION.OGPS, pathParamId),
    {
      id: (await getMaxId(constants.FIREBASE_COLLECTION.OGPS)) + 1,
      image_url: url,
      title: title,
      created_at: now,
      updated_at: now,
    }
  );
}

/// プロフィールアイコンをアップロード
/// @param {any} image - 画像ファイル
/// @param {string} ext - 拡張子
/// @return {string} - firebase storage上のプロフィールアイコンURL
export async function uploadImage(image: any, directory: string, path: string) {
  let imageUrl = "";
  imageUrl = await _uploadTaskPromise(image, directory, path);
  return imageUrl;
}

/// 退会処理
export async function withdrawal() {
  await update(
    constants.FIREBASE_COLLECTION.PROFILES,
    store.state.myUser.docId,
    {
      is_enable: false,
    }
  );

  const uid = store.state.auth.currentUser.uid;
  const usersDocId = (await getUsersId(uid)).docId;

  await update(constants.FIREBASE_COLLECTION.USERS, usersDocId, {
    firebase_auth_uuid: "deleted",
  });

  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
    where("users_id", "==", store.state.myUser.users_id),
    where("is_open", "==", true)
  );

  const snapshot = await getDocs(q);
  snapshot.forEach(async (doc: any) => {
    await update(constants.FIREBASE_COLLECTION.ARTICLES, doc.id, {
      is_open: false,
      is_enable: false,
    });
  });
}

/// 指定されたコレクションの最大IDを取得
/// @param {string} collectionName - コレクション名
/// @return {number} - 最大ID
export async function getMaxId(collectionName: string) {
  let maxId = 0;
  const q = query(
    collection(store.state.db, collectionName),
    orderBy("id", "desc"),
    limit(1)
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc: any) => {
    const user = doc.data();
    maxId = user.id;
  });

  return maxId;
}

export async function addDocument(collectionName: string, document: any) {
  return await addDoc(collection(store.state.db, collectionName), document);
}

/// 新規登録
/// @param {string} uid - 新規登録するユーザのuid
export async function register(uid: string) {
  const now = new Date();
  const usersId = (await getMaxId(constants.FIREBASE_COLLECTION.USERS)) + 1;
  await addDocument(constants.FIREBASE_COLLECTION.USERS, {
    id: usersId,
    firebase_auth_uuid: uid,
    created_at: now,
    updated_at: now,
  });

  const myUser: { [key: string]: any } = {
    id: (await getMaxId(constants.FIREBASE_COLLECTION.PROFILES)) + 1,
    users_id: usersId,
    image_url: "",
    is_enable: true,
    name: "",
    qiita_url: "",
    twitter_url: "",
    zenn_url: "",
    github_url: "",
    first_profile_editing: true,
    created_at: now,
    updated_at: now,
  };
  myUser["docId"] = (
    await addDocument(constants.FIREBASE_COLLECTION.PROFILES, myUser)
  ).id;
  store.commit("setMyUser", myUser);

  await addDocument(constants.FIREBASE_COLLECTION.NOTIFICATIONS, {
    id: (await getMaxId(constants.FIREBASE_COLLECTION.NOTIFICATIONS)) + 1,
    users_id: usersId,
    thanks_notification_counts: 0,
    created_at: now,
    updated_at: now,
  });
}

/// 記事取得
/// @return {any} - 記事
export async function getArticles(lastDocSnapshot: any) {
  const queryParams = [
    where("is_enable", "==", true),
    where("is_open", "==", true),
    orderBy("created_at", "desc"),
    limit(constants.UPDATE_BASE_COUNT),
  ];

  if (lastDocSnapshot != null) {
    queryParams.push(startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(
    query(
      collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
      ...queryParams
    )
  );
  return _getDocsAndLastSnapshot(snapshot);
}

/// 指定した1人のユーザが作成した記事を取得
/// @param {number} usersId - ユーザのid
/// @param {boolean} isMyself - 自分の記事かどうか
/// @return {any} - 記事
export async function getArticlesOfOneUser(
  usersId: number,
  isMyself: boolean,
  lastDocSnapshot: any
) {
  const queryParams = [
    where("users_id", "==", usersId),
    where("is_enable", "==", true),
    orderBy("created_at", "desc"),
    limit(constants.UPDATE_BASE_COUNT),
  ];

  if (!isMyself) {
    queryParams.push(where("is_open", "==", true));
  }

  if (lastDocSnapshot != null) {
    queryParams.push(startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(
    query(
      collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
      ...queryParams
    )
  );
  return _getDocsAndLastSnapshot(snapshot);
}

/// 記事の著者のユーザIDを用いて、thanksドキュメントを取得
/// @param {number} authorUsersId - 著者のユーザID
/// @return {any} - 記事
export async function getThanksByAuthorUsersId(
  authorUsersId: number,
  lastDocSnapshot: any
) {
  const queryParams = [
    where("added", "==", true),
    where("author_users_id", "==", authorUsersId),
    orderBy("updated_at", "desc"),
    limit(constants.UPDATE_BASE_COUNT),
  ];

  if (lastDocSnapshot != null) {
    queryParams.push(startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(
    query(
      collection(store.state.db, constants.FIREBASE_COLLECTION.THANKS),
      ...queryParams
    )
  );
  return _getDocsAndLastSnapshot(snapshot);
}

/// thanksした記事を取得
/// @return {any} - 記事
export async function getSentThanks(lastDocSnapshot: any) {
  const queryParams = [
    where("added", "==", true),
    where("users_id", "==", store.state.myUser.id),
    orderBy("updated_at", "desc"),
    limit(constants.UPDATE_BASE_COUNT),
  ];

  if (lastDocSnapshot != null) {
    queryParams.push(startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(
    query(
      collection(store.state.db, constants.FIREBASE_COLLECTION.THANKS),
      ...queryParams
    )
  );
  return _getDocsAndLastSnapshot(snapshot);
}

/// タグ検索ページの、ドキュメントを取得
/// @param {string} tag - タグ
/// @return {any} - 記事
export async function getArticlesFromTag(tag: string, lastDocSnapshot: any) {
  const queryParams = [
    where("is_enable", "==", true),
    where("is_open", "==", true),
    where("exact_tags", "array-contains", tag),
    orderBy("created_at", "desc"),
    limit(constants.UPDATE_BASE_COUNT),
  ];

  if (lastDocSnapshot != null) {
    queryParams.push(startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(
    query(
      collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
      ...queryParams
    )
  );
  return _getDocsAndLastSnapshot(snapshot);
}

/// thanksドキュメントを更新する
/// @param {number} articlesId - 記事ID
/// @param {number} nowThanksCount - 現在のthanks数
/// @param {string} articlesDocId - 記事のドキュメントID
/// @param {number} authorUsersId - 記事の著者のユーザID
/// @return {any} - 新しいthanksドキュメント
export async function updateThanksCount(
  articlesId: number,
  nowThanksCount: number,
  articlesDocId: string,
  authorUsersId: number
) {
  let updatedThanks: { [key: string]: any } = {};
  const now = new Date();
  const thanks = store.state.thanks[articlesId];

  if (thanks != undefined) {
    thanks["added"] = !thanks["added"];

    await update(constants.FIREBASE_COLLECTION.THANKS, thanks["docId"], {
      added: thanks["added"],
    });

    updatedThanks = thanks;
  } else {
    updatedThanks["added"] = true;

    updatedThanks["docId"] = (
      await addDocument(constants.FIREBASE_COLLECTION.THANKS, {
        id: (await getMaxId(constants.FIREBASE_COLLECTION.THANKS)) + 1,
        users_id: store.state.myUser.users_id,
        articles_id: articlesId,
        author_users_id: authorUsersId,
        added: true,
        created_at: now,
        updated_at: now,
      })
    ).id;
  }

  await update(constants.FIREBASE_COLLECTION.ARTICLES, articlesDocId, {
    thanks: nowThanksCount,
  });

  return updatedThanks;
}

/// thanksに関する通知状況を更新する
/// @param {string} type - 更新の種別
/// @param {number} articleAuthorUsersId - 記事作成者のユーザID
export async function updateNotice(type: string, articleAuthorUsersId: number) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.NOTIFICATIONS),
    where("users_id", "==", articleAuthorUsersId),
    limit(1)
  );
  let docData: { [key: string]: any } = {};

  const snapshot = await getDocs(q);
  snapshot.forEach((doc: any) => {
    docData = doc.data();
    docData["docId"] = doc.id;
  });

  const updateDoc: { [key: string]: any } = {};

  if (type == "add") {
    updateDoc[
      "thanks_notification_counts"
    ] = ++docData.thanks_notification_counts;
  } else if (type == "confirmed") {
    updateDoc["thanks_notification_counts"] = 0;
  } else {
    if (docData.thanks_notification_counts > 0) {
      updateDoc[
        "thanks_notification_counts"
      ] = --docData.thanks_notification_counts;
    }
  }

  await update(
    constants.FIREBASE_COLLECTION.NOTIFICATIONS,
    docData.docId,
    updateDoc
  );
}

/// 1つの記事を取得
/// @param {string} pathParamId 記事path_param_id
/// @return {any} - 記事
export async function getSingleArticle(pathParamId: string) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
    where("path_param_id", "==", pathParamId),
    where("is_enable", "==", true),
    limit(1)
  );
  let docData: { [key: string]: any } = {};

  const snapshot = await getDocs(q);
  snapshot.forEach((doc: any) => {
    docData = doc.data();
    docData["docId"] = doc.id;
  });

  if (Object.getOwnPropertyNames(docData).length != 0 && !docData["is_open"]) {
    // 公開状態では無かった場合
    if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
      // ログインユーザ情報が入っていた場合
      if (store.state.myUser.users_id != docData["users_id"]) {
        // 作者が自分でなかった場合
        docData = {};
      }
    } else {
      docData = {};
    }
  }

  return docData;
}

/// 記事IDを元に、記事を取得
/// @param {number} articleId - 記事ID
/// @return {any} 記事
export async function getSingleArticleByArticlesId(articlesId: number) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.ARTICLES),
    where("id", "==", articlesId),
    where("is_enable", "==", true),
    limit(1)
  );

  const snapshot = await getDocs(q);
  return _getDocsAndLastSnapshot(snapshot);
}

/// 記事別のthanksステータスを取得
/// @param {number} userId - thanksしたユーザの、usersId
/// @return {any} - 記事別のthanksステータス
export async function getMyThanksStatus(usersId: number) {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.THANKS),
    where("users_id", "==", usersId)
  );

  const snapshot = await getDocs(q);
  const thanks: { [key: string]: { [key: string]: any } } = {};
  snapshot.forEach((doc: any) => {
    const docData = doc.data();
    thanks[docData["articles_id"]] = {
      docId: doc.id,
      added: docData.added,
    };
  });

  return thanks;
}

/// 検索処理
/// @param {string} selectedSection - 選択しているセクション
/// @param {string} text - 検索したい文字列
/// @return {any} - 検索結果のドキュメント
export async function getSearch(
  selectedSection: string,
  candidates: string[],
  lastDocSnapshot: any
) {
  let collectionName;
  const queryParams = [
    where("is_enable", "==", true),
    where(`search_candidates.${candidates[0]}`, "==", true),
    limit(constants.UPDATE_BASE_COUNT),
  ];

  if (selectedSection == "articles") {
    collectionName = constants.FIREBASE_COLLECTION.ARTICLES;
    queryParams.push(where("is_open", "==", true));
  } else {
    collectionName = constants.FIREBASE_COLLECTION.PROFILES;
  }

  if (candidates.length == 2) {
    queryParams.push(where(`search_candidates.${candidates[1]}`, "==", true));
  } else if (candidates.length == 3) {
    queryParams.push(where(`search_candidates.${candidates[2]}`, "==", true));
  } else if (candidates.length == 4) {
    queryParams.push(where(`search_candidates.${candidates[3]}`, "==", true));
  } else if (candidates.length == 4) {
    queryParams.push(where(`search_candidates.${candidates[4]}`, "==", true));
  }

  if (lastDocSnapshot != null) {
    queryParams.push(startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(
    query(collection(store.state.db, collectionName), ...queryParams)
  );
  return _getDocsAndLastSnapshot(snapshot);
}

<template>
  <div id="bottom-navigation">
    <nav id="bottom-navigation-nav">
      <ul>
        <li
          id="bottom-navigation-search"
          class="bottom-navigation-icon"
          @click="search(router)"
        >
          <img src="@/assets/images/search-icon.png" alt="" />
        </li>
        <li
          id="bottom-navigation-add"
          class="bottom-navigation-icon"
          @click="add(router, state)"
        >
          <img src="@/assets/images/add-icon.png" alt="" />
        </li>
        <li
          id="bottom-navigation-home"
          class="bottom-navigation-icon"
          @click="goHome(router)"
        >
          <img src="@/assets/images/footer/home-icon.png" alt="" />
        </li>
        <li
          v-if="
            Object.getOwnPropertyNames(store.state.myUser).length === 0 ||
              store.state.myUser.image_url == ''
          "
          id="bottom-navigation-default-profile-icon"
          class="bottom-navigation-icon"
          @click="clickedProfile(router, state)"
        >
          <img
            src="@/assets/images/profile-icon.png"
            alt=""
            :class="{
              'have-notification': store.state.haveThanksNotification,
            }"
          />
        </li>
        <li
          v-else
          id="bottom-navigation-original-profile-icon"
          class="bottom-navigation-icon"
          @click="clickedProfile(router, state)"
        >
          <img
            :src="store.state.myUser.image_url"
            alt=""
            :class="{
              'have-notification': store.state.haveThanksNotification,
            }"
          />
        </li>
      </ul>
    </nav>

    <DialogBase
      @close="closeModal(state, constants.MODAL.LOGIN)"
      v-if="state.loginModal"
    >
      <!-- default スロットコンテンツ -->
      <div id="dialog-close-icon-button-parent">
        <i
          id="dialog-close-icon-button"
          class="far fa-times-circle"
          @click="closeModal(state, constants.MODAL.LOGIN)"
        ></i>
      </div>
      <div id="dialog-promotion-text">ログインする必要があります</div>
      <LoginButtons></LoginButtons>
      <!-- /default -->
    </DialogBase>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import DialogBase from "@/components/parts/DialogBase.vue";
import LoginButtons from "@/components/parts/LoginButtons.vue";
import { closeModal } from "@/components/menuBarFunc.ts";
import {
  goHome,
  search,
  add,
  clickedProfile,
} from "@/components/menuBarFunc.ts";

export default defineComponent({
  components: {
    DialogBase,
    LoginButtons,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      loginModal: false,
    });

    return {
      state,
      store,
      router,
      search,
      add,
      goHome,
      clickedProfile,
      closeModal,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/globals.scss";
@import "@/assets/styles/baseDialog.scss";

$ORIGINAL_PROFILE_IMG_SIZE: 2em;

#bottom-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: solid 3px $THEME_COLOR;
  background-color: white;

  @include mq-up() {
    display: none;
  }
}

#bottom-navigation-nav {
  > ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
}

.bottom-navigation-icon {
  display: inline-block;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > a {
    margin-top: 5px;
  }

  & img {
    width: 1.75em;
    height: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 0.2em solid transparent;
  }
}

.have-notification {
  border: 0.2em solid blue !important;
}

#bottom-navigation-default-profile-icon {
  > img {
    border-radius: 3em;
  }
}

#bottom-navigation-original-profile-icon {
  padding: 0em;
  background-color: transparent;

  > img {
    width: $ORIGINAL_PROFILE_IMG_SIZE;
    height: $ORIGINAL_PROFILE_IMG_SIZE;
    border-radius: 3em;
    padding: 0em;
  }
}
</style>

/// modalを開く
/// @param {any} state - state
/// @param {string} modal - modal
export function openModal(state: any, modal: string) {
  document.body.classList.add("modal-open");

  state[modal] = true;
}

/// modalを閉じる
/// @param {any} state - state
/// @param {string} modal - modal
export function closeModal(state: any, modal: string) {
  document.body.classList.remove("modal-open");

  state[modal] = false;
}

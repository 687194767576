
import { defineComponent, reactive } from "vue";
import { getProfileByUsersId } from "@/components/firebaseOperations";
import {
  getThanksSubscribe,
  getFormatDate,
  getCleansingThanksCounts,
  getSns,
  updatedThanksCount,
  clickedOpenButton,
  deleteArticle,
  doCopy,
  clickedDeleteIcon,
  clickedEditIcon,
  clickedTag,
  clickedProfile,
  compiledMarkdownText,
} from "@/components/aboutArticleFunc";
import store from "@/store";
import DialogBase from "@/components/parts/DialogBase.vue";
import LoginButtons from "@/components/parts/LoginButtons.vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { getTargetThanksIcon } from "@/components/thanksIcons";
import { closeModal } from "@/components/modalFunc";
import constants from "@/components/constants";

export default defineComponent({
  components: {
    DialogBase,
    LoginButtons,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  async setup(props) {
    const router = useRouter();

    onBeforeRouteLeave(() => {
      if (state.thanksSubscribe !== undefined) {
        state.thanksSubscribe();
      }
    });

    const state = reactive({
      article: props.article,
      cleansingCreatedDate: "",
      displayCounts: "0",
      clickedThanksIcons: false,
      user: {} as { [key: string]: any },
      thanksIconPath: "",
      sns: [] as { [key: string]: string }[],
      loginModal: false,
      deleteModal: false,
      thanksSubscribe: undefined as any,
      isMyself: false,
    });

    if (store.state.thanks[state.article.id] != undefined) {
      state.clickedThanksIcons = store.state.thanks[state.article.id]["added"];
    }

    state.thanksSubscribe = getThanksSubscribe(state);
    state.cleansingCreatedDate = getFormatDate(
      state.article.created_at.seconds
    );
    state.thanksIconPath = getTargetThanksIcon(state.article.thanks);
    state.displayCounts = getCleansingThanksCounts(state.article.thanks);
    state.user = await getProfileByUsersId(
      state.article.users_id,
      false
    );
    state.sns = getSns(false, state.user);

    if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
      state.isMyself = store.state.myUser.users_id === state.user.users_id;
    }

    /// main codeを開くための矢印ボタンをクリックした時
    /// @param {any} e - element
    const backActive = async (e: any) => {
      const classes = e.currentTarget.className;
      const targetString = " back-active";
      const index = classes.indexOf(targetString);
      if (index == -1) {
        // main codeを開いていなかった場合
        e.currentTarget.className += targetString; // class追加

        e.target.parentElement.parentElement.parentElement.parentElement.lastElementChild.style.boxShadow =
          "0.2em 0.2em 0.6em rgba(187, 187, 187, 0.8)";

        e.target.parentElement.parentElement.parentElement.parentElement.lastElementChild.style.paddingTop =
          "1.5em";
        e.target.parentElement.parentElement.parentElement.parentElement.lastElementChild.style.display =
          "block";
      } else {
        // main codeを開いていた場合
        e.currentTarget.className = classes.slice(0, index); // 対象class名削除
        e.target.parentElement.parentElement.parentElement.parentElement.lastElementChild.style.boxShadow =
          "0.3em 0.3em 0.5em rgba(150, 150, 150, 0.0)";
        e.target.parentElement.parentElement.parentElement.parentElement.lastElementChild.style.display =
          "none";
        e.target.parentElement.parentElement.parentElement.parentElement.lastElementChild.style.paddingTop =
          "1.0em";
      }
    };

    /// thanksアイコンをクリックした時に実行
    const clickedThanks = async () => {
      await updatedThanksCount(state);
    };

    /// 記事公開ボタンをクリックした時の実行
    const clickOpenButton = async () => {
      await clickedOpenButton(state);
    };

    /// 記事をクリックした時
    const clickedArticle = () => {
      router.push({
        path: `/article/${state.article.path_param_id}`,
      });
    };

    /// 記事削除処理
    const deletedArticle = async () => {
      await deleteArticle(state.article.docId);
      state.article.is_enable = false;
      state.article.is_open = false;
      closeModal(state, constants.MODAL.DELETE);
    };

    /// main codeをコピー
    /// @param {any} e - element
    const copy = (e: Event) => {
      doCopy(e, state);
    };

    /// 何もしない関数
    const noSomething = () => {
      // no something
    };

    return {
      constants,
      router,
      store,
      state,
      backActive,
      deletedArticle,
      copy,
      clickOpenButton,
      clickedArticle,
      noSomething,
      closeModal,
      clickedThanks,
      clickedDeleteIcon,
      clickedEditIcon,
      clickedTag,
      clickedProfile,
      compiledMarkdownText,
    };
  },
});

import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import hljs from "highlight.js"; // Import code highlight file
import "highlight.js/styles/monokai-sublime.css"; // Import code highlight
import masonry from 'vue-next-masonry'; // pinterest UIのためのライブラリをimport
import VueLoaders from 'vue-loaders';
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

let firebaseConfig: any = {
  apiKey: "AIzaSyAbly-yvlgoA1d9v_s781pB_8EQ98Y7e6U",
  authDomain: "dogearsdev.firebaseapp.com",
  projectId: "dogearsdev",
  storageBucket: "dogearsdev.appspot.com",
  messagingSenderId: "889154989120",
  appId: "1:889154989120:web:3d4cb6143a1ecffb7883a5"
};

if (process.env.NODE_ENV === "production") {
  firebaseConfig = { // firebaseを利用するためのデータ
    apiKey: "AIzaSyAsY0m-PL6FjyngTTLH7Z4j4hhqiduCnoc",
    authDomain: "dogears-80f81.firebaseapp.com",
    projectId: "dogears-80f81",
    storageBucket: "dogears-80f81.appspot.com",
    messagingSenderId: "906273921388",
    appId: "1:906273921388:web:81746612e260f5152efb49",
    measurementId: "G-E308FZFVR2",
  };
} 

const app = initializeApp(firebaseConfig);
store.commit("setStorage", getStorage(app));
store.commit("setDb", getFirestore());
store.commit("setAuth", getAuth());
getAnalytics(app);

createApp(App)
  .directive("highlight", function(el) { // コードハイライトを利用するための設定
    const blocks = el.querySelectorAll("pre code");
    blocks.forEach((block: any) => {
      hljs.highlightElement(block);
    });
  })
  .use(store)
  .use(router)
  .use(masonry)
  .use(VueLoaders)
  .mount("#app");